import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-construction',
    templateUrl: './construction.html',
    styleUrls: []
})
export class ConstructionComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
