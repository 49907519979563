export function formState() {
    return {
        name: '¿Sobre qué quieres contactarnos?',
        options: [
            {
                icon: 'mas/generalmas.png',
                name: 'Preguntas generales',
                form: genericForm('general')
            }, {
                icon: 'mas/tartasmas.png',
                name: 'Presupuesto tartas',
                options: [
                    {
                        icon: 'sabores/sabores.png',
                        name: 'Pide tu presupuesto para tarta básica',
                        form: genericCakeForm()
                    }, {
                        icon: 'tamanos/XL+.png',
                        name: 'Pide tu presupuesto para tarta personalizada',
                        form: customCakeForm()
                    }
                ]
            }, {
                icon: 'mas/cupcakesmas.png',
                name: 'Presupuesto cupcakes',
                form: cupcakesForm('ask-cupcake')
            }, {
                icon: 'mas/galletasmas.png',
                name: 'Presupuesto galletas',
                form: genericOthersForm('ask-biscuits')
            }, {
                icon: 'mas/mesasmas.png',
                name: 'Presupuesto eventos',
                form: eventForm('ask-event')
            }, {
                icon: 'pedir/catalogo.png',
                name: 'Catálogo',
                options: [
                    {
                        icon: 'mas/tartasmas.png',
                        name: 'Repostería personalizada',
                        link: 'https://crownsbakery.com/assets/catalogo/CrownBakery-ReposteriaPersonalizada.pdf'
                    }, {
                        icon: 'mas/mesasmas.png',
                        name: 'Eventos',
                        link: 'https://crownsbakery.com/assets/catalogo/CrownBakery-Eventos.pdf'
                    }
                ]
            }
        ]
    }
}

export function sharedValues(topForm: any[] = []): any[] {

    return topForm.concat([
        {
            name: 'name',
            text: 'Nombre',
            visible: true,
            type: 'text',
            value: null,
            required: true
        }, {
            name: 'email',
            text: 'Email',
            visible: true,
            type: 'text',
            value: null,
            required: true
        }, {
            name: 'phone',
            text: 'Telefono',
            visible: true,
            type: 'text',
            value: null,
            required: true
        }, {
            name: 'status',
            text: '',
            visible: false,
            value: 'new'
        }, {
            name: 'creationDate',
            text: '',
            visible: false,
            value: new Date()
        }, {
            name: 'resolveDate',
            text: '',
            visible: false,
            value: null
        },
    ]);
}

export function genericForm(type) {
    return sharedValues().concat([
        {
            name: 'type',
            text: '',
            visible: false,
            value: type
        }, {
            name: 'message',
            text: 'Cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null,
            required: true
        }
    ]);
}

export function eventForm(type) {
    return sharedValues().concat([
        {
            name: 'type',
            text: '',
            visible: false,
            value: type
        }, {
            name: 'orderDate',
            text: 'Fecha del evento',
            visible: true,
            type: 'date',
            value: null,
            required: true
        }, {
            name: 'message',
            text: 'Qué tipo de evento quieres celebrar, cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null,
            required: true
        }
    ]);
}

export function genericCakeForm() {
    const topForm = [
        {
            type: 'image-show',
            icon: 'iconos/basicCakes.png',
            visible: true,
            name: 'image'
        }
    ]
    return sharedValues(topForm).concat([
        {
            name: 'size',
            text: 'Tamaño',
            type: 'options',
            options: [
                {
                    key: 'small',
                    value: 'Pequeña (8 raciones)',
                    icon: 'tamanos/S.png',
                    size: 120
                },
                {
                    key: 'medium',
                    value: 'Mediana (10 raciones)',
                    icon: 'tamanos/M.png',
                    size: 140
                },
                {
                    key: 'large',
                    value: 'Grande (15 raciones)',
                    icon: 'tamanos/L.png',
                    size: 160
                },
                {
                    key: 'xlarge',
                    value: 'Muy grande (20 raciones)',
                    icon: 'tamanos/XL.png',
                    size: 180
                },
                // {
                //     key: 'more',
                //     value: 'Más',
                //     icon: 'tamanos/XL+.png',
                //     size: 180
                // }
            ],
            visible: true,
            value: null,
            required: true
        },
        cakeTaste(),
        {
            name: 'type',
            text: '',
            visible: false,
            value: 'generic-cake'
        }, {
            name: 'orderDate',
            text: 'Fecha de recogida',
            visible: true,
            type: 'date',
            value: null,
            required: true
        }, {
            name: 'message',
            text: 'Cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null,
            required: true
        }
    ]);
}

export function customCakeForm() {
    return sharedValues().concat([
        {
            name: 'size',
            text: 'Nº de raciones (min 10)',
            type: 'number',
            visible: true,
            value: null,
            required: true
        }, {
            name: 'floor',
            text: 'A partir de 18 raciones, la tarta podrá ser de dos pisos',
            type: 'span',
            visible: true,
            value: null
        },
        cakeTaste(),
        {
            name: 'extras',
            text: 'Extras',
            type: 'options-multiple',
            options: [
                {
                    key: 'figure',
                    value: 'Muñeco'
                },
                {
                    key: 'photo',
                    value: 'Foto impresa'
                },
                {
                    key: 'sugar-flowers',
                    value: 'Flores de azucar'
                },
                {
                    key: 'toppers',
                    value: 'Toppers'
                }
            ],
            visible: true,
            value: []
        }, {
            name: 'type',
            text: '',
            visible: false,
            value: 'custom-cake'
        }, {
            name: 'orderDate',
            text: 'Fecha de recogida',
            visible: true,
            type: 'date',
            value: null,
            required: true
        }, {
            name: 'image',
            text: 'Si tienes alguna idea mándanos tu foto',
            visible: true,
            type: 'image',
            value: null
        }, {
            name: 'message',
            text: 'Cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null,
            required: true
        }
    ]);
}

function cakeTaste() {
    return {
        name: 'taste',
        text: 'Sabor',
        type: 'options',
        options: [
            {
                key: 'black-oreo',
                value: 'CHOCOLATE HÚMEDO CON CREMA SUAVE DE NUTELLA Y OREO',
                icon: 'sabores/oreo.png',
                size: 120
            },
            {
                key: 'red-velvet',
                value: 'RED VELVET',
                icon: 'sabores/redVelvet.png',
                size: 120
            },
            {
                key: 'vainilla-oreo',
                value: 'VAINILLA CON CREMA SUAVE DE NUTELLA Y OREO',
                icon: 'sabores/vainillaOreo.png',
                size: 120
            },
            {
                key: 'vainilla-queso',
                value: 'VAINILLA CON CREMA DE QUESO',
                icon: 'sabores/vainilla_cremaqueso.png',
                size: 120
            },
            {
                key: 'vainilla-ganacheChocolate',
                value: 'VAINILLA CON GANACHE DE CHOCOLATE',
                icon: 'sabores/vainillaChocolate.png',
                size: 120
            },
            {
                key: 'carrot-cake',
                value: 'CARROT CAKE',
                icon: 'sabores/carrotCake.png',
                size: 120
            },
            {
                key: 'fresa-limon',
                value: 'FRESA CON CREMA SUAVE DE LIMON',
                icon: 'sabores/fresaLimon.png',
                size: 120
            },
            {
                key: 'comments',
                value: 'PREGUNTA POR TU SABOR FAVORITO',
                icon: 'sabores/saboresPreguntas.png',
                size: 120
            }
        ],
        visible: true,
        value: null,
        required: true
    }
}

export function cupcakesForm(type) {
    return sharedValues().concat([
        {
            name: 'size',
            text: 'Tamaño',
            type: 'radio',
            options: [
                {
                    value: 'Caja de 12 cupcakes',
                    key: '12box'
                },
                {
                    value: 'Caja de 18 cupcakes',
                    key: '18box'
                },
                {
                    value: 'Caja de 24 cupcakes',
                    key: '24box'
                },
                {
                    value: 'Consulta para pedido mayores',
                    key: 'more'
                },
            ],
            visible: true,
            value: null,
            required: true
        }, {
            name: 'decoration',
            text: 'Decoración',
            type: 'radio',
            options: [
                {
                    value: 'Simple',
                    key: 'simple'
                },
                {
                    value: 'Decoración personalizada',
                    key: 'personalized'
                }
            ],
            visible: true,
            value: null,
            required: true
        }, {
            name: 'type',
            text: '',
            visible: false,
            value: type
        }, {
            name: 'orderDate',
            text: 'Fecha de recogida',
            visible: true,
            type: 'date',
            value: null,
            required: true
        }, {
            name: 'image',
            text: 'Imagen',
            visible: true,
            type: 'image',
            value: null
        }, {
            name: 'message',
            text: 'Cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null,
            required: true
        }
    ]);
}

export function genericOthersForm(type) {
    return sharedValues().concat([
        {
            name: 'floor',
            text: 'Pedido mínimo para galletas decoradas con glasa 10 del mismo modelo. Para distintos modelos 8 de cada.',
            type: 'span',
            visible: true,
            value: null
        }, {
            name: 'floor',
            text: 'Pedido mínimo para galletas decoradas con impresión comestible 10. Pueden ser modelos variados.',
            type: 'span',
            visible: true,
            value: null
        }, {
            name: 'size',
            text: 'Nº de raciones',
            type: 'number',
            visible: true,
            value: null,
            required: true
        }, {
            name: 'decoration',
            text: 'Decoración',
            type: 'radio',
            options: [
                {
                    value: 'Decoración glasa',
                    key: 'glasa'
                },
                {
                    value: 'Decoración impresión comestible',
                    key: 'impressed'
                }
            ],
            visible: true,
            value: null,
            required: true
        }, {
            name: 'type',
            text: '',
            visible: false,
            value: type
        }, {
            name: 'orderDate',
            text: 'Fecha de recogida',
            visible: true,
            type: 'date',
            value: null,
            required: true
        }, {
            name: 'image',
            text: 'Imagen',
            visible: true,
            type: 'image',
            value: null
        }, {
            name: 'message',
            text: 'Cuéntanos tu idea',
            visible: true,
            type: 'textarea',
            value: null
        }
    ]);
}
