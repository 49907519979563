import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    data: Date = new Date();
    focus;
    focus1;

    username: string;
    password: string;

    constructor(
        public auth: AngularFireAuth,
        public _rout: Router
    ) { }

    ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');

        this.auth.onAuthStateChanged(res => {
            console.log('User logged!')
            this._rout.navigateByUrl('admin');
        })
    }

    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    login() {
        this.auth.signInWithEmailAndPassword(this.username, this.password).then(
            res => {
                console.log('Login res: ', res);
                this._rout.navigateByUrl('admin')
            }
        ).catch(
            err => {
                console.error('Login error: ', err);
            }
        )
    }

}
