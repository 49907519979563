import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    animations: [
        trigger('scrollAnimation', [
            state('show', style({
                opacity: 1
            })),
            state('hide', style({
                opacity: 0
            })),
            transition('show => hide', animate('700ms ease-out')),
            transition('hide => show', animate('700ms ease-in'))
        ])
    ]
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;

    user: any;

    // Go To top animation
    state = 'hide'

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentPosition = this.element.nativeElement.offsetTop
        const scrollPosition = window.pageYOffset

        if (scrollPosition >= (window.outerHeight * 0.7)) {
            this.state = 'show'
        } else {
            this.state = 'hide'
        }

    }

    constructor(
        public location: Location,
        private element: ElementRef,
        public auth: AngularFireAuth,
        private router: Router
    ) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.auth.onAuthStateChanged(res => {
            console.log('User logged!')
            this.user = res;
        })
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isDocumentation() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        } else {
            return false;
        }
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    logout() {
        this.user = null;
        this.auth.signOut();
        this.router.navigateByUrl('login');
    }
}
