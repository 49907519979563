import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as Rellax from 'rellax';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/storage';

export interface Item {
  email: string;
  message: string;
  name: string;
  status: string;
  type: string;
}

function comparator(filterLocalDateAtMidnight, cellValue) {
  const dateAsString = cellValue;
  const cellDate = dateAsString ? dateAsString.toDate() : new Date(null);
  cellDate.setHours(0, 0, 0, 0);

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  subscriptionArray: Subscription[] = [];

  itemsCollectionNew: AngularFirestoreCollection<Item>;
  itemsCollectionResolved: AngularFirestoreCollection<Item>;
  itemsCollectionTotal: AngularFirestoreCollection<Item>;

  itemsNew: Item[];
  itemsResolved: Item[];
  itemsTotal: Item[];

  headElements: string[] = ['ID', 'Nombre', 'Estado', 'Mensaje'];
  selected: string;

  @ViewChild('content') modalContent;
  selectedItem: any;
  imageUrl: any;

  columnDefs = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    {
      headerName: 'Fecha creación', field: 'creationDate', sortable: true, filter: 'agDateColumnFilter', cellRenderer: (data) => {
        const dat = data.data.creationDate;
        if (!dat) {
          return ''
        }
        if (typeof (dat) === 'string') {
          return new Date(dat).toLocaleString();
        }
        return data.data.creationDate.toDate().toLocaleString()
      }, filterParams: {
        comparator: comparator
      },
    },
    {
      headerName: 'Fecha entrega', field: 'orderDate', sortable: true, filter: 'agDateColumnFilter', cellRenderer: (data) => {
        const dat = data.data.orderDate;
        if (!dat) {
          return ''
        }
        if (typeof (dat) === 'string') {
          return new Date(dat).toLocaleDateString();
        }
        return data.data.orderDate.toDate().toLocaleDateString()
      }, filterParams: {
        comparator: comparator
      },
    },
    {
      headerName: 'Fecha resolución', field: 'resolveDate', sortable: true, filter: 'agDateColumnFilter', cellRenderer: (data) => {
        const dat = data.data.resolveDate;
        if (!dat) {
          return ''
        }
        if (typeof (dat) === 'string') {
          return new Date(dat).toLocaleString();
        }
        return dat.toDate().toLocaleString();
      }, filterParams: {
        comparator: comparator
      },
    },
    { headerName: 'Tipo', field: 'type', sortable: true, filter: 'agSetColumnFilter' },
    { headerName: 'Nombre', field: 'name', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    {
      headerName: 'Estado', field: 'status', sortable: true, filter: 'agSetColumnFilter', editable: true, cellEditorSelector: (params) => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: ['new', 'readed', 'resolved']
          }
        }
      },
    },
    { headerName: 'Mensaje', field: 'message', sortable: true, filter: true }
  ];

  constructor(
    private afs: AngularFirestore,
    private modalService: NgbModal,
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
    const rellaxHeader = new Rellax('.rellax-header');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('profile-page');
    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');

    this.getData();
    this.selected = 'new';
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile-page');
    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');


  }

  getData() {
    // NEW
    this.itemsCollectionNew = this.afs.collection<Item>('contact', ref => ref.where('status', '==', 'new'));
    this.subscriptionArray.push(this.itemsCollectionNew.valueChanges({ idField: 'id' }).subscribe(
      res => this.itemsNew = res
    ));

    // Resolved
    this.itemsCollectionResolved = this.afs.collection<Item>('contact', ref => ref.where('status', '==', 'resolved'));
    this.subscriptionArray.push(this.itemsCollectionResolved.valueChanges({ idField: 'id' }).subscribe(
      res => this.itemsResolved = res
    ));

    // Total
    this.itemsCollectionTotal = this.afs.collection<Item>('contact');
    this.subscriptionArray.push(this.itemsCollectionTotal.valueChanges({ idField: 'id' }).subscribe(
      res => this.itemsTotal = res
    ));
  }

  unsuscribe() {
    this.subscriptionArray.forEach(
      e => e.unsubscribe()
    );
  }

  onCellValueChanged(ev) {
    console.log('cellEditingStopped', ev);
    if (ev.data.status === 'resolved') {
      ev.data.resolveDate = new Date();
    }
    this.itemsCollectionTotal.doc(ev.data.id).update(ev.data);
  }

  onDoubleClickCell(event) {
    if (event.colDef.field === 'status') {
      return;
    }
    console.log('DoubleClick ', event);
    this.afs.collection<Item>('contact').doc(event.data.id).ref.get().then(
      res => {
        if (res.exists) {
          console.log('Document data:', res.data());
          this.selectedItem = res.data();
          if (this.selectedItem.image) {
            this.imageUrl = this.storage.ref(this.selectedItem.image).getDownloadURL();
          }
          if (this.selectedItem.creationDate) {
            this.selectedItem.creationDate = new Date(this.selectedItem.creationDate.toMillis()).toLocaleDateString();
          }
          if (this.selectedItem.resolveDate) {
            this.selectedItem.resolveDate = new Date(this.selectedItem.resolveDate.toMillis()).toLocaleDateString();
          }
          if (this.selectedItem.orderDate) {
            this.selectedItem.orderDate = new Date(this.selectedItem.orderDate.toMillis()).toLocaleDateString();
          }
          this.modalService.open(this.modalContent, { ariaLabelledBy: 'modal-basic-title' });
        } else {
          console.log('No such document!');
        }
      }
    );
  }

}
