import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';

export interface Item {
  name: string;
  description: string;
  image: string;
  imageUrl: Observable<any>;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit {
  data: Date = new Date();
  focus;
  focus1;
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  subscriptionArray: Subscription[] = [];
  itemsCollectionNews: AngularFirestoreCollection<Item>;
  items: Item[];

  constructor(
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,
    private storage: AngularFireStorage,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    // var rellaxHeader = new Rellax('.rellax-header');

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');

    this.auth.onAuthStateChanged(res => {
      this.itemsCollectionNews = this.afs.collection<Item>('news', ref => ref.where('published', '==', true));
      this.subscriptionArray.push(this.itemsCollectionNews.valueChanges({ idField: 'id' }).subscribe(
        res => {
          this.items = res
          this.items.map(el=>{
            el.imageUrl = this.storage.ref(el.image).getDownloadURL()
          })
        }
      ));
    })

    // const feed = new Instafeed({
    //     get: 'user',
    //     userId: 'USER_ID'
    // });
    // feed.run();
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
    const navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');

    this.subscriptionArray.forEach(
      e => e.unsubscribe()
    );
  }

  ngAfterViewInit() {

  }

  contactAnalitics() {
    this.analytics.logEvent('contact_button');
  }

  openUrl(url) {
    window.open(url, '_blank').focus();
  }
}
