import { Component, OnInit, ViewChild } from '@angular/core';
import { Item } from 'app/examples/profile/profile.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formState } from './form.generator';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { DatePickerOptions } from '@ngx-tiny/date-picker';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    formStateSelected: any[];
    formStateBack: any[];

    formGroup: FormGroup;
    formObj: any[];

    private itemsCollection: AngularFirestoreCollection<Item>;

    fileToUpload: File = null;

    isAPerson: boolean;
    isAPersonError: boolean;
    isHoliday: boolean;

    thanksPage: boolean;

    @ViewChild('contact') contact: any;

    datePickerOptions: DatePickerOptions = {
        minDate: new Date(new Date().setDate(new Date().getDate() + 7))
    };


    constructor(
        private afs: AngularFirestore,
        private storage: AngularFireStorage,
        private analytics: AngularFireAnalytics,
        private messagin: AngularFireMessaging
    ) { }

    ngOnInit(): void {
        this.itemsCollection = this.afs.collection<Item>('contact');
        this.formStateSelected = formState().options;
        this.updateIcons();
        this.formStateBack = [];
    }

    select(category) {
        this.formStateBack.push(this.formStateSelected);
        if (category.options) {
            this.formStateSelected = category.options;
            this.updateIcons();
        } else if (category.link) {
            window.open(category.link, '_blank');
        } else {
            // Show form
            this.formStateSelected = null;
            this.loadForm(category.form);
        }
    }

    back() {
        this.formStateSelected = this.formStateBack.pop();
        this.formObj = null;
        this.formGroup = null;
        this.contact.nativeElement.scrollIntoView({ behavior: 'smooth' })
    }

    loadForm(form) {
        const formControlArray = {};
        form.forEach(element => {
            formControlArray[element.name] = new FormControl(element.value, element.required ? Validators.required : null);
            if (element.type === 'options') {
                (element.options as any[]).forEach(option => {
                    option.url = option.icon ? this.storage.ref(option.icon).getDownloadURL() : null;
                });
            }
            if (element.type === 'image-show') {
                element.url = element.icon ? this.storage.ref(element.icon).getDownloadURL() : null;
            }
        });

        this.formGroup = new FormGroup(formControlArray);
        this.formObj = form;
    }

    send() {
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const OrderDate = this.formGroup.get('orderDate').value
        const initHol = new Date('07/29/2022');
        const endHol = new Date('08/31/2022');
        this.isHoliday = false;
        if (OrderDate >= initHol && OrderDate <= endHol) {
            // console.log('Holyday!!!', this.formGroup.get('orderDate').value)
            this.isHoliday = true;
            return;
        }

        this.isAPersonError = false;
        if (!this.isAPerson) {
            this.isAPersonError = true;
            return;
        }

        const item: any = this.formGroup.value;
        this.itemsCollection.add(item).then(
            res => {
                console.log('Succ: ', res);
                // Upload image
                if (this.formGroup.get('image') && this.formGroup.get('image').value != null) {
                    const route = 'uploads/' + res.id;
                    this.addImage(route);
                    item.image = route;
                    res.update(item);
                }
                this.formGroup = null;
                this.formObj = null;
                this.formStateSelected = null;
                this.formStateBack = [];
                this.thanksPage = true;
                this.contact.nativeElement.scrollIntoView({ behavior: 'smooth' })
                this.analytics.logEvent('send_contact');
            }
        ).catch(
            err => console.error('Error: ', err)
        );
    }

    resolved(captchaResponse: string) {
        console.log(`Resolved captcha with response: ${captchaResponse}`);
        this.isAPerson = !!captchaResponse;
        this.isAPersonError = !this.isAPerson;
    }

    addImage(route: string) {
        const storageRef = this.storage.ref(route);
        storageRef.put(this.fileToUpload);
    }

    visible(array: any[]) {
        return array.filter(e => {
            return e.visible
        });
    }

    updateIcons() {
        this.formStateSelected.forEach(e => {
            if (!e.url) {
                e.url = this.storage.ref(e.icon).getDownloadURL();
            }
        })
    }

    selectOption(item, key): void {
        console.log('Select option')
        item.value = key;
        this.formGroup.get(item.name).setValue(key);
    }

    hasError(item): boolean {
        return this.formGroup.get(item.name).errors && this.formGroup.get(item.name).touched;
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    onChangeMultiSelect(item, option, event) {
        console.log('Multiselect change', item, event.currentTarget.checked);
        const find = (item.value as string[]).indexOf(option.value);
        if (find !== -1) {
            (item.value as string[]).splice(find, 1);
        } else {
            (item.value as string[]).push(option.value);
        }
    }
}
