// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDxpCGFLWoq1kTI5yUE_OMwMaSlm6Ozk4M',
    authDomain: 'crownsbakery-754a2.firebaseapp.com',
    databaseURL: 'https://crownsbakery-754a2.firebaseio.com',
    projectId: 'crownsbakery-754a2',
    storageBucket: 'crownsbakery-754a2.appspot.com',
    messagingSenderId: '563782597331',
    appId: '1:563782597331:web:95a8a2e527fcdd8effb226',
    measurementId: 'G-ZN6V39H7TZ'
  }
};
